.loaderContainer {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContent {
  background-color: #fff;
  margin-block: 2em;
  padding: 2em 2em;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContent::before {
  content: '🕕';
  position: absolute;
  font-size: 2.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 1000ms ease-in-out 0ms infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
