.recent-content {
  padding: min(1em, 10vw);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: repeat(3, 1fr);
  column-gap: 0.6em;

  padding: min(0.6em, 6vw);
  background-color: var(--card-special-background);
}

.recent-content + .recent-content {
  margin-block-start: min(0.4em, 1vw);
}

.text-badge {
  grid-row: 1/3;
  align-self: center;
  background-color: var(--light-red);
  color: var(--text-light);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.request-name {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  font-size: 0.95rem;
  color: var(--text-light);
}

.request-address {
  grid-column: 2/3;
  grid-row: 2/3;
  font-size: 0.85em;
  color: var(--text-gray);
}

.request-view {
  grid-column: 1/-1;
  grid-row: 3/4;
  align-self: center;
  display: flex;
  flex-grow: 1;
  width: auto;
  justify-content: center;
  /* margin-inline-start: auto; */
  padding: 0.5rem;
  background-color: var(--card-button-background);
  color: var(--text-light);
}

.request-view:hover,
.request-view:focus-visible {
  background-color: var(--card-button-hover);
}

.request-name small {
  font-size: 0.9rem;
  margin-left: 0.5em;
  color: var(--text-gray);
}

.request-view-group {
  display: flex;
  grid-column: 1/-1;
  grid-row: 3/4;
  margin-top: 0.5em;
  justify-content: center;
}

@media (min-width: 600px) {
  .recent-content {
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: repeat(2, 1fr);
  }

  .request-view {
    grid-column: 3/4;
    grid-row: 1/-1;
    margin-inline-start: auto;
    padding: 0.8em 1.2em;
  }

  .request-view-group {
    grid-column: 3/4;
    grid-row: 1/-1;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .request-address {
    font-size: 0.9rem;
  }

  .request-name {
    font-size: 1.1rem;
  }
}
