.sidebar-div {
  display: inline-flex;
  visibility: hidden;
  position: fixed;
  bottom: 0;
  top: var(--header-height);
  z-index: 3;
  opacity: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.sidebar-content {
  transform: translateX(-100%);
  overflow-y: auto;
  transition: 0.3s ease;
  width: 300px;
  padding: 20px;
  flex-direction: column;
  background-color: var(--sidebar-background);
  margin-top: 1px;
  user-select: none;
}

.sidebar-element {
  display: flex;
  gap: 20px;
  padding: 10px;
  align-items: center;
  background-color: transparent;
  margin: 2px 0px;
  transition: 0.25s linear;
  border-left: 3px solid var(--sidebar-background);
  cursor: pointer;
}

.sidebar-element.active,
.sidebar-element:hover,
.sidebar-element:focus-visible {
  background-color: var(--dark-blue);
  border-left: 3px solid var(--sidebar-elements);
}

.sidebar-element.sidebar-element svg {
  width: 20px;
  height: 20px;
}

.sidebar-element svg path {
  width: 20px;
  height: 20px;
  fill: var(--sidebar-elements);
}

.sidebar-element p {
  color: var(--sidebar-elements);
  font-size: 1.1em;
}

.sidebar-element.active p,
.sidebar-element:hover p,
.sidebar-element:focus-visible p {
  color: var(--text-light);
}

.sidebar-div.active {
  display: flex;
  opacity: 1;
  visibility: visible;
  overflow: hidden;
}

.sidebar-div.active .sidebar-content {
  transform: translateX(0);
}

@media (min-width: 1200px) {
  .sidebar-div {
    height: 100%;
    width: max-content;
    display: flex;
    top: 70.4px;
    visibility: visible;
    align-self: start;
    justify-self: start;
    opacity: 1;
    transform: none;
  }

  .sidebar-content {
    transform: none;
  }
}
