.message-container {
  align-self: center;
  position: fixed;
  padding: 0.8rem 1.5rem;
  display: none;
  grid-template-columns: 1fr max-content;
  bottom: 0;
  margin: 0 auto;
  column-gap: 0.8em;
  border-radius: 5px;
  background-color: var(--button-background);
  color: var(--text-light);
  width: 95%;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in-out;
}

.message-button-dismiss {
  align-self: center;
  border-radius: 50%;
  border: none;
  background-color: transparent;
}

.message-button-dismiss svg path {
  fill: var(--text-gray);
  transition: fill 0.2s ease-in-out;
}

.message-button-dismiss:hover svg path {
  fill: var(--text-light);
}

.message-div-text {
  justify-self: column-start;
  align-self: center;
}

@media (min-width: 768px) {
  .message-container {
    max-width: 500px;
    width: max-content;
    right: 10px;
    align-self: flex-end;
  }
}
