.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 0.8rem;
}

.input {
  height: 40px;
  padding-inline: 0.4em;
  font-size: 1rem;
}

.label {
  color: var(--text-gray);
  font-size: 1rem;
  margin-bottom: 0.3em;
}
