.profile-container {
  display: grid;
  grid-gap: 20px;
}

.input-group > p {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--text-light);
}

.input-buttons {
  margin-top: 1.7em;
  display: grid;
  column-gap: 0.5em;
  grid-template-columns: repeat(2, max-content);
}

.profile-update,
.profile-edit {
  background-color: var(--button-background);
}

.profile-update:hover,
.profile-update:focus,
.profile-edit:hover,
.profile-edit:focus-visible {
  background-color: var(--button-hover);
}

.cancel-update {
  background-color: transparent;
  color: var(--text-gray);
}

.cancel-update:hover,
.cancel-update:focus-visible {
  background: unset;
  color: var(--light-red);
}

.profile-update:disabled {
  background-color: var(--light-dark-blue);
  color: var(--light-dark-blue);
  cursor: not-allowed;
  position: relative;
  transition: all 300ms ease;
}

.profile-update:disabled::before {
  content: '↻';
  font-size: 1.11rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 1500ms ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.profile-update:not(button:disabled):hover {
  background-color: var(--light-dark-blue);
  color: var(--text-light);
}
