:root {
  --header-height: 70px;

  /* project colors */
  --light-dark-blue: #333544;
  --dark-blue: #1f1f2b;
  --light-blue: #46485c;
  --light-gray: #92a8bb;
  --light-red: #ff7979;
  --dark-red: #dc4f4f;
  --red: F10000;
  --light: #fff;

  /* specific ui colors */
  --background: var(--light-dark-blue);

  /* text */
  --text-light: var(--light);
  --text-gray: var(--light-gray);
  --text-dark: var(--light-blue);

  /* button */
  --button-background: var(--light-blue);
  --button-red-background: var(--light-red);
  --button-hover: var(--dark-blue);

  /* sidebar */
  --sidebar-background: var(--light-blue);
  --sidebar-elements: var(--light-gray);
  --sidebar-element-hover: var(--dark-blue);

  /* cards */
  --card-background: var(--light-blue);
  --card-button-background: var(--dark-blue);
  --card-button-hover: var(--light-dark-blue);

  /* recent requests */
  --card-special-background: #414356;

  /* image update button */
  --image-button: #3534349f;
  --image-button-hover: #333333dc;

  /* heading font sizes */
  --heading1: 2.3rem;
  --heading2: 2rem;
  --heading3: 1.4rem;
  --heading4: 1.1rem;
  --heading5: 1rem;
  --heading6: 0.9rem;
}
