.popup-container {
  display: flex;
  position: fixed;
  inset: 0;
  z-index: 10;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.2);
}

.popup-content {
  margin-block: 2em;
  padding: 1.25rem;
  background-color: var(--card-button-background);
  color: var(--text-light);
  width: 92%;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  padding-block: 5px;
  align-items: center;
}

.popup-dismiss {
  cursor: pointer;
  padding: 0.1em 0.5em;
  border-radius: 4px;
  background-color: var(--button-background);
  transition: transform 0.3s ease-in;
}

.popup-dismiss:hover,
.popup-dismiss:focus-visible {
  transform: scale(1.05);
}

.popup-header h2 {
  font-size: 1rem;
}

@media (min-width: 600px) {
  .popup-content {
    width: 40rem;
  }
}
