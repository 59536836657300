.input-group {
  display: flex;
  flex-direction: column;
  max-width: 650px;
}

hr {
  border: none;
  background-color: var(--text-gray);
  height: 1px;
}

.input-group + .input-group {
  margin-block: 0.8em;
}

.input-group > :first-child {
  margin-block-end: 0.3rem;
}

.input-group label {
  color: var(--text-gray);
}

.input-group input,
.input-group select {
  height: 2.5rem;
  padding-inline: 10px;
  font-size: 1.1rem;
}

/* button */
.button {
  outline: none;
  border: none;
  padding: 0.8em 1.5em;
  font-weight: bold;
  background-color: var(--button-background);
  color: #fff;
  transition: all 0.2s linear;
}

.button-group .button:disabled {
  background-color: var(--button-hover);
  color: var(--button-hover);
  cursor: not-allowed;
  position: relative;
  transition: all 300ms ease;
}

.button-group .button:disabled::before {
  content: '↻';
  font-size: 1.11rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate 1500ms ease-in-out infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.button-group .button:not(button:disabled):hover,
.button-group .button:not(button:disabled):focus-visible {
  background-color: var(--button-hover);
  color: var(--text-light);
}

.button-group {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button-group > .button + .button {
  margin-inline-start: 0.8em;
}
